@media only screen and (max-width : 480px) {
    h1 {
        font-size: 1.5rem !important;
        margin: 20px 0 25px;
    }
    h2 {
        font-size: 1.3rem !important;
    }
    button,
    a[role=button] {
        max-width: 290px;
    }
    footer {
        text-align: center !important;
    }
    footer div {
        padding-left: 0 !important;
        padding-right: 0 !important;
        text-align: center !important;
    }
    footer *,
    footer h4 {
        text-align: center !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    footer ul {
        display: block !important;
        width: 100% !important;
    }
    footer ul li:last-child a {
        padding-right: 0 !important;
    }

}